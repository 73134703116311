import styled from "styled-components";

const Contact = () => {
  return (
    <MainContainer id="contact">
      <div>
        <h2>
          <span>004.</span> Contact
        </h2>
      </div>
      <div className="contact">
        <p>
          Je suis actuellement à la recherche d'un stage en entreprise (et
          éventuellement d'une embauche en suivant!) sur Toulouse et ses
          environs du 22/05/2023 au 24/07/2023.
        </p>
        <p className="contact2">Vous pouvez me contacter par mail:</p>
        <p>
          <a
            href="mailto:bocognani.gael@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            bocognani.gael@gmail.com
          </a>
        </p>
        <p>ou bien par message sur Linkedin : </p>
        <p>
          <a
            href="https://www.linkedin.com/in/ga%C3%ABl-bocognani-6aba2522a/"
            target="_blank"
            rel="noreferrer"
          >
            Linkedin
          </a>
        </p>
      </div>
    </MainContainer>
  );
};

export default Contact;

const MainContainer = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  min-height: 40vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h2 {
    padding: 0 0 20px 20px;
    margin: 0;
    color: var(--color-secondary);
    max-width: 650px;
    font-size: 30px;
    transition: 0.5s;
    span {
      color: var(--color-other);
      transition: 0.5s;
    }
    &:hover {
      color: var(--color-other);
      transition: 0.5s;

      span {
        color: var(--color-primary);
        transition: 0.5s;
      }
    }
  }
  .contact {
    text-align: center;
    max-width: 700px;
    min-height: 200px;
    font-size: 1.3rem;
    color: var(--color-other);
    a {
      color: var(--color-secondary);
      transition: 0.5s;
      &:hover {
        color: var(--color-other);
        transition: 0.5s;
      }
    }
    p {
      margin: 0;
      line-height: 1.6rem;
    }
    .contact2 {
      padding-top: 15px;
    }
  }
`;
