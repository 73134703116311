import styled from "styled-components";

const Competences = () => {
  return (
    <MainContainer id="competences">
      <div>
        <div className="flex_noreverse">
          <div>
            <h2>
              <span>002.</span> Compétences
            </h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti
              quidem nam adipisci suscipit, accusantium accusamus nemo debitis
              eos? Consequatur consectetur ullam voluptatibus, tenetur in quasi
              blanditiis necessitatibus corrupti ratione numquam?
            </p>
          </div>
          <div className="logo">
            <div>
              <img src="img/language/HTML5.png" alt="Logo HTML5" />
            </div>
            <div>
              <img src="img/language/CSS3.png" alt="Logo CSS3" />
            </div>
            <div>
              <img src="img/language/javascript.png" alt="Logo Javascript" />
            </div>
            <div>
              <img src="img/language/React.png" alt="Logo React.js" />
            </div>
            <div>
              <img src="img/language/php.png" alt="Logo PHP" />
            </div>
            <div>
              <img src="img/language/MySQL.png" alt="Logo MySQL" />
            </div>
            <div>
              <img src="img/language/Symphony.png" alt="Logo Symphony" />
            </div>
            <div>
              <img src="img/language/Java.png" alt="Java" />
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default Competences;

const MainContainer = styled.section`
  max-width: 1200px;
  /* border: 1px solid white; */
  margin: 0 auto;
  min-height: 50vh;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  h2 {
    padding: 0 0 20px 20px;
    margin: 0;
    color: var(--color-secondary);
    max-width: 650px;
    font-size: 30px;
    transition: 0.5s;
    span {
      color: var(--color-other);
      transition: 0.5s;
    }
    &:hover {
      color: var(--color-other);
      transition: 0.5s;

      span {
        color: var(--color-primary);
        transition: 0.5s;
      }
    }
  }
  p {
    max-width: 550px;
    font-size: 20px;
    padding: 0 20px;
    margin: 0;
    color: var(--color-other);
  }
  .logo {
    display: flex;
    max-width: 500px;
    flex-wrap: wrap;
    gap: 35px;
    margin-left: 50px;
    transition: 0.5s;
    padding-top: 10px;
  }
  img {
    max-height: 70px;
    &:hover {
      transform: scale(1.2);
      transition: 0.5s;
    }
  }
`;
