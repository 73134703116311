import styled from "styled-components";

const Hero = () => {
  return (
    <MainContainer>
      <div>
        <p>Bonjour les gens, je m'appelle</p>
      </div>
      <div>
        <h1>Gaël Bocognani.</h1>
      </div>
      <div>
        <h3>
          Je suis Concepteur Développeur d'Applications en cours de formation.
        </h3>
      </div>
      <div>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi
          soluta temporibus aspernatur aliquid ducimus, ab velit ipsum
          voluptate? Tempore exercitationem quam eveniet consequuntur alias
          voluptas ex ut, temporibus nostrum facere!
        </p>
      </div>
    </MainContainer>
  );
};

export default Hero;

const MainContainer = styled.section`
  min-height: 100vh;
  width: 100%;
  /* border: 1px solid red; */
  padding-left: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  & > div {
    max-width: 100%;
    /* border: 1px solid green; */
    h1 {
      color: var(--color-primary);
      padding: 10px 40px;
      font-size: 4rem;
      margin: 0;
    }
    h3 {
      padding: 0 0 20px 40px;
      margin: 0;
      color: var(--color-secondary);
      max-width: 650px;
      font-size: 2rem;
    }

    p {
      padding: 0 40px;
      font-size: 1.5rem;
      max-width: 550px;
      color: var(--color-other);
      margin: 0;
    }
  }
`;
