import styled from "styled-components";

const Header = () => {
  return (
    <MainContainer>
      <nav>
        <div>Logo</div>
        <div>
          <ol>
            <li>
              <a href="#me">
                <span> 001.</span> It's me
              </a>
            </li>
            <li>
              <a href="#competences">
                <span>002.</span> Compétences
              </a>
            </li>
            <li>
              <a href="#portfolio">
                <span>003.</span> Portfolio
              </a>
            </li>
            <li>
              <a href="#contact">
                <span>004.</span> Contact
              </a>
            </li>
          </ol>
          <a className="cvButton" href="cv.pdf" target="_blank">
            CV
          </a>
        </div>
      </nav>
    </MainContainer>
  );
};

export default Header;

const MainContainer = styled.header`
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 20;

  nav {
    min-height: 75px;
    padding: 10px 60px;
    display: flex;
    justify-content: space-between;
    /* border: 1px solid black; */
    background: #200463a7;
    backdrop-filter: blur(4px);
    & > div {
      display: flex;
      align-items: center;
      .cvButton {
        padding: 5px 20px;
        border: 1px solid var(--color-other);
        color: var(--color-other);
        border-radius: 10px;
        &:hover {
          border: 1px solid var(--color-primary);
          color: var(--color-primary);
          transition: 0.5s;
        }
      }
    }

    ol {
      display: flex;
      justify-content: flex-end;
      color: var(--color-primary);
      list-style: none;
      padding-right: 30px;
      gap: 15px;

      span {
        color: var(--color-other);
        transition: 0.5s;
      }
      a {
        color: var(--color-primary);
        transition: 0.5s;
      }
      a:hover {
        color: var(--color-other);
        transition: 0.5s;
        cursor: crosshair;
        span {
          color: var(--color-primary);
          transition: 0.5s;
        }
      }
    }
  }
`;
