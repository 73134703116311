import styled from "styled-components";

const BarreGauche = () => {
  return (
    <MainContainer className="barreGauche">
      <a
        href="https://www.linkedin.com/in/ga%C3%ABl-bocognani-6aba2522a"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fa-brands fa-linkedin"></i>
      </a>
      <a href="https://github.com/bigwhoop0" target="_blank" rel="noreferrer">
        <i className="fa-brands fa-square-github"></i>
      </a>
      <a
        href="mailto:bocognani.gael@gmail.com"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fa-solid fa-square-envelope"></i>
      </a>
    </MainContainer>
  );
};

export default BarreGauche;

const MainContainer = styled.div`
  width: 50px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  /* border: 1px solid green; */
  min-height: 100px;
  z-index: 20;

  i {
    font-size: 47px;
    color: var(--color-other);
    margin-bottom: 10px;
    transition: 0.5s;

    &:hover {
      color: var(--color-primary);
      transform: scale(1.1) rotate(10deg);
      transition: 0.5s;
    }
  }
`;
