import styled from "styled-components";

const ItsMe = () => {
  return (
    <MainContainer id="me">
      <div>
        <div>
          <h2>
            <span>001.</span> It's me
          </h2>
        </div>
        <div>
          <p>
            Passionné d’informatique depuis l’enfance, je me suis formé en
            autodidacte en maintenance informatique. J’ai également suivi
            plusieurs cours en ligne ainsi que des ouvrages sur le
            développement.
          </p>
        </div>
        <div>
          <p>
            Je suis actuellement une formation de concepteur développeur
            d’applications chez AP Formation afin d’en faire mon métier.
          </p>
        </div>
      </div>
    </MainContainer>
  );
};

export default ItsMe;

const MainContainer = styled.section`
  height: 50vh;
  width: 100%;
  /* border: 1px solid red; */
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  h2 {
    padding: 0 0 20px 20px;
    margin: 0;
    color: var(--color-secondary);
    max-width: 650px;
    font-size: 30px;
    transition: 0.5s;
    span {
      color: var(--color-other);
      transition: 0.5s;
    }
    &:hover {
      color: var(--color-other);
      transition: 0.5s;

      span {
        color: var(--color-primary);
        transition: 0.5s;
      }
    }
  }
  p {
    max-width: 550px;
    font-size: 20px;
    padding: 0 20px;
    margin: 0;
    color: var(--color-other);
  }
`;
