import styled from "styled-components";

const Description = ({ titre }) => {
  if (titre === 0)
    return (
      <MainContainer className="description">
        <h1>Black Jack</h1>
        <p>Un petit jeu de Black Jack avec les règles basique.</p>
      </MainContainer>
    );
  else
    return (
      <MainContainer className="description">
        <h1>Animation Mario</h1>
        <p>Une animation de Mario réalisée en Javascript et CSS.</p>
      </MainContainer>
    );
};

export default Description;

const MainContainer = styled.div`
  width: 100%;
  position: absolute;
  /* border: 1px solid red; */
  min-height: 90px;
  border-radius: 0 0 10px 10px;
  background-color: #2004638f;
  bottom: 0px;
  text-align: center;
  h1 {
    color: var(--color-primary);
    /* padding: 0; */
    margin: 0;
    padding: 10px 0;
  }
`;
