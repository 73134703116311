import { useState, useRef, useCallback, useEffect } from "react";
import styled from "styled-components";
import Description from "./Description";

const Portfolio = () => {
  const [imgCrea, setimgCrea] = useState(0);
  const interval = useRef();

  const startCrea = useCallback(() => {
    interval.current = setInterval(() => {
      setimgCrea((prevState) => {
        return ++prevState % 2;
      });
    }, 3000);
  }, []);

  useEffect(() => {
    startCrea();
    return () => clearInterval(interval.current);
  }, [startCrea]);

  return (
    <MainContainer id="portfolio">
      <div className="flex">
        <div
          className="creation"
          style={{ backgroundImage: `url(img/creations/${imgCrea}.png)` }}
        >
          <Description titre={imgCrea}></Description>
        </div>
        <div className="portfolioContain">
          <h2>
            <span>003.</span> Portfolio
          </h2>
          <p>
            Quelques créations codées en dehors des cours pour{" "}
            <span>m'amuser</span> m'entraîner.
          </p>
          <div className="buttonPortfolio">
            <a className="portfolioButton" href="creations/blackjack/">
              Black Jack
            </a>
            <a className="portfolioButton" href="creations/marioanim/">
              Animation Mario
            </a>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default Portfolio;

const MainContainer = styled.section`
  min-height: var(--height-portfolio);
  width: 100%;

  display: flex;
  align-content: space-around;
  flex-direction: column;
  justify-content: center;
  .portfolioContain {
    padding-left: 20px;
    padding-right: 20px;
  }
  p {
    max-width: 550px;
    font-size: 20px;
    margin: 0;
    color: var(--color-other);
    padding-bottom: 15px;
    span {
      text-decoration: line-through;
    }
  }

  h2 {
    padding: 0 0 20px 0px;
    margin: 0;
    color: var(--color-secondary);
    max-width: 650px;
    font-size: 30px;
    transition: 0.5s;
    span {
      color: var(--color-other);
      transition: 0.5s;
    }
    &:hover {
      color: var(--color-other);
      transition: 0.5s;

      span {
        color: var(--color-primary);
        transition: 0.5s;
      }
    }
  }
  .buttonPortfolio {
    display: flex;
    align-items: flex-start;
    flex-direction: var(--direction);
  }
  .portfolioButton {
    padding: 5px 10px;
    border: 1px solid var(--color-other);
    color: var(--color-other);
    border-radius: 10px;
    margin: 5px 5px;
    transition: 0.5s;
    &:hover {
      border: 1px solid var(--color-primary);
      color: var(--color-primary);
      transition: 0.5s;
    }
  }

  .creation {
    position: relative;
    margin-top: 20px;
    height: var(--height-image-portfolio);
    width: 530px;
    border-radius: 10px;
    /* border: 1px solid red; */
    /* margin-right: 2rem; */
    background-size: contain;
    background-repeat: no-repeat;
    content: "";
  }
`;
