import styled from "styled-components";
import Hero from "../components/Hero";
import ItsMe from "../components/ItsMe";
import Competences from "../components/Competences";
import Portfolio from "../components/Portfolio";
import Contact from "../components/Contact";

const Main = () => {
  return (
    <MainContainer>
      <Hero />
      <ItsMe />
      <Competences />
      <Portfolio />
      <Contact />
    </MainContainer>
  );
};

export default Main;

const MainContainer = styled.main`
  max-width: var(--size-main);
  /* border: 1px solid white; */
  margin: 100px auto;
  min-height: 100vh;
`;
