import styled from "styled-components";

const BarreDroiteHaut = () => {
  return (
    <MainContainer className="barreDroiteHaut">
      <div className="mail">
        <a
          href="mailto:bocognani.gael@gmail.com"
          target="_blank"
          rel="noreferrer"
        >
          bocognani.gael@gmail.com
        </a>
      </div>
    </MainContainer>
  );
};

export default BarreDroiteHaut;
const MainContainer = styled.div`
  width: 50px;
  position: fixed;
  bottom: 35vh;
  right: 20px;
  min-height: 310px;
  z-index: 20;
  .mail {
    writing-mode: vertical-lr;
    width: 50px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &::before {
      height: 50px;
      width: 1px;
      content: "";
      background-color: var(--color-other);
      display: block;
      margin-right: 5px;
      margin-bottom: 5px;
    }

    &::after {
      height: 50px;
      width: 1px;
      content: "";
      background-color: var(--color-other);
      display: block;
      margin-right: 5px;
      margin-top: 5px;
    }

    a {
      color: var(--color-other);
      transition: 0.5s;
      font-style: "Roboto";
      &:hover {
        transform: scale(1.02);
        transition: 1s;
        color: var(--color-primary);
      }
    }
  }
`;
