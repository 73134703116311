import Header from "./layers/Header";
import Main from "./layers/Main";
import { Fragment } from "react";
import BarreGauche from "./components/BarreGauche";
import BarreDroite from "./components/BarreDroite";
import Footer from "./layers/Footer";
import BarreDroiteHaut from "./components/BarreDroiteHaut";

const Root = () => {
  return (
    <Fragment>
      <BarreGauche />
      <BarreDroiteHaut />
      <BarreDroite />
      <Header />
      <Main />
      <Footer />
    </Fragment>
  );
};

export default Root;
