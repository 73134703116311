import styled from "styled-components";

const Footer = () => {
  return (
    <MainContainer>
      <p>
        <span>©2023</span> GAEL-BOCOGNANI.COM
      </p>
    </MainContainer>
  );
};

export default Footer;

const MainContainer = styled.footer`
  width: 100%;
  min-height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    text-align: center;
    color: white;
    font-size: 0.8rem;
    transition: 0.5s;
    span {
      color: var(--color-secondary);
      transition: 0.5s;
    }
    &:hover {
      color: var(--color-secondary);
      transition: 0.5s;

      span {
        color: var(--color-other);
        transition: 0.5s;
      }
    }
  }
`;
