import styled from "styled-components";
//////////////////////////////////
///////// Murray//////////////////
/////////////////////////////////
const BarreDroite = () => {
  return <MainContainer></MainContainer>;
};

export default BarreDroite;

const MainContainer = styled.div`
  width: 80px;
  position: fixed;
  bottom: 0;
  right: 20px;
  /* border: 1px solid green; */
  min-height: 100px;
  z-index: 20;
  svg:hover {
    fill: var(--color-primary);
  }
`;
